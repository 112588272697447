<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-2.png"
    ></v-img>

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        You are not authorized! 🔐
      </h2>
      <p class="text-sm">
        You don’t have permission to access this page. Go Home!
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/not-authorized.png"
        ></v-img>
      </div>
      <v-btn
        v-if="user.length < 1"
        color="primary"
        to="/"
        class="mb-4"
        @click="logoutUser"
      >
        Back to login
      </v-btn>
      <v-btn
        v-else
        color="primary"
        to="/"
        class="mb-4"
      >
        Back to home
      </v-btn>
    </div>
  </div>
</template>
<script>
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { router } = useRouter()
    const user = JSON.parse(localStorage.getItem('userAbility'))

    const logoutUser = () => {
      // Remove user from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove user & Ability from localStorage
      localStorage.removeItem('user')
      localStorage.removeItem('userAbility')

      // Reset ability
      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    return {
      logoutUser,
      user,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
